import * as React from "react";

import { DataGridPro, DataGridProProps } from "@mui/x-data-grid-pro";

import colors from "../Theme/colors";
import ThemeLayout from "../Theme/ThemeLayout";

const DataGrid = (
  props: DataGridProProps
): React.ReactElement<DataGridProProps> => {
  const styles = {
    "&.MuiDataGrid-root": {
      position: "absolute",
      width: "100%",
      height: "fit-content",
      maxHeight: "100%",
      minHeight: "30rem",
      "*": {
        transition: "unset"
      }
    },
    ".MuiDataGrid-cell": {
      outline: "none",
      "&:focus": {
        outline: "none"
      },
      "&:focus-within": {
        outline: "none"
      }
    },
    ".MuiDataGrid-toolbarContainer": {
      backgroundColor: colors.gray[90],
      padding: "5px",
      ".MuiButton-root": {
        fontSize: "1rem !important"
      }
    },
    ".MuiDataGrid-row--borderBottom, .MuiDataGrid-columnHeader": {
      backgroundColor: `${colors.gray[90]}`
    },
    ".MuiDataGrid-columnHeaderTitle": {
      fontWeight: "600",
      fontSize: "0.875rem"
    },
    ".MuiDataGrid-headerFilterRow": {
      backgroundColor: `${colors.gray[90]}`
    },
    ".MuiButton-root": {
      fontSize: "1rem",
      maxHeight: "34px"
    },
    ".MuiDataGrid-row": {
      fontWeight: "500",
      fontSize: "1rem",
      lineHeight: "1.5rem",
      backgroundColor: colors.white[0],
      "&.Mui-selected, &:hover": {
        fontWeight: "600",
        backgroundColor: colors.teal[90]
      }
    },
    ".MuiToolbar-root": {
      backgroundColor: colors.gray[90]
    },
    ".MuiTablePagination-selectLabel": {
      marginBottom: 0
    },
    ".MuiDataGrid-scrollbar--vertical": {},
    ".custom-cell": {
      display: "flex",
      textDecoration: "none",
      color: "inherit",
      cursor: "pointer",
      fontSize: "1rem",
      margin: 0,
      padding: 0,
      "& .MuiDataGrid-cell[data-field='_arrow']": {
        borderLeft: "none"
      },
      "&:hover": {
        color: colors.black[0]
      }
    },
    ".clickable": {
      cursor: "pointer"
    },
    ".MuiDataGrid-overlayWrapper": {
      top: 0
    },
    ".MuiDataGrid-virtualScroller": {
      backgroundColor: colors.gray[90]
    },
    ".MuiDataGrid-overlayWrapperInner": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      backgroundColor: colors.white[0]
    },
    ".MuiDataGrid-filler": {
      backgroundColor: colors.gray[90],
      border: "none"
    },
    ".MuiDataGrid-scrollbarFiller--header": {
      backgroundColor: `${colors.gray[90]} !important`
    },
    ".MuiDataGrid-columnHeader--pinnedRight": {
      border: "none",
      "& ~ div": {
        border: "none"
      }
    },
    ".caret-right": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  };

  return (
    <ThemeLayout>
      <div className="flex flex-col h-full max-h-full relative">
        <DataGridPro {...props} sx={{ ...styles, ...props.sx }}></DataGridPro>
      </div>
    </ThemeLayout>
  );
};

export default DataGrid;
