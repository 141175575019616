import * as React from "react";

import {
  GridApiPro,
  GridCellProps,
  GridColumnVisibilityModel,
  GridDensity,
  GridFilterItem,
  GridFilterModel,
  GridPaginationModel,
  GridPreferencePanelsValue,
  GridRenderCellParams,
  GridRowsProp,
  GridSortItem,
  GridSortModel
} from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";

import { CommunicationGridHeaders } from "./Communicate/CommunicateGridHeaders";

export type ExternalActionsObj = {
  showFilterPanel: () => void;
  showColumnManagerPanel: () => void;
  setDensityCompact: () => void;
  setDensityStandard: () => void;
  setDensityComfortable: () => void;
  areFiltersApplied: () => number;
  reloadData?: () => void;
  getData?: () => any;
};

export type DataGridProps = {
  searchText?: string;
  fetchData: ({
    ordering,
    page,
    limit
  }: {
    ordering?: string;
    page?: number;
    limit?: number;
    [key: string]: unknown;
  }) => Promise<{ data: unknown[]; count: number } | undefined>;
  onSelectRow?: (selectedRows: any[]) => void;
  onClickRow?: (selectedRow: any) => void;
  initPage?: number;
  initPageSize?: number;
  storageType?: "LOCAL" | "SESSION";
  setExternalActionsObj?: (externalActionObject: ExternalActionsObj) => void;
};

export type FilterValue = {
  team_lead: string[];
  division: string[];
  groups: string[];
  active_events: string[];
  role: string[];
  attendance_points: { [key: string]: string };
  sick_points: { [key: string]: string };
  pto_points: { [key: string]: string };
  vacation_points: { [key: string]: string };
  flex_points: { [key: string]: string };
  other_points: { [key: string]: string };
};

export const defaults = {
  data: { rows: [], count: 0 },
  density: "compact" as GridDensity,
  storageType: "SESSION" as "SESSION" | "LOCAL",
  pageSize: 100,
  page: 0,
  ordering: ""
};

export const getStoredState = (
  storage: Storage,
  storageKey: string
): GridInitialStatePro => {
  const gridData = storage?.getItem(storageKey);
  if (gridData) {
    try {
      return JSON.parse(gridData);
    } catch (e) {
      console.error(e);
    }
  }
  return {};
};

export const sortModelToString = (
  sortModel: GridSortModel | undefined
): string | undefined => {
  return sortModel
    ?.map((prop: GridSortItem) => {
      return `${prop.sort === "asc" ? "" : "-"}${prop.field}`;
    })
    .join(",")
    .replaceAll("team_lead", "team_lead__name")
    .replaceAll("active_events", "active_event_label_name")
    .replaceAll("division", "division__name");
};

export const numericFilterToObj = (
  filter: GridFilterItem | undefined
): { [key: string]: string } => {
  if (!filter) {
    return {};
  }
  const { field, operator, value } = filter;
  if (!field || !operator) {
    return {};
  }
  const operatorDir = {
    "=": "exact",
    ">": "gt",
    "<": "lt",
    ">=": "gte",
    "<=": "lte",
    "!=": "ne"
  } as any;
  const operatorValue = {
    isEmpty: "True",
    isNotEmpty: "False"
  } as any;
  if (operator in operatorValue) {
    return { [`${field}__isnull`]: `${operatorValue[operator]}` };
  }
  if (value === undefined) {
    return {};
  }
  return { [`${field}__${operatorDir[operator]}`]: `${value}` };
};

export const showFilterPanel = (
  apiRef: React.MutableRefObject<GridApiPro>,
  filterModel: GridFilterModel | undefined,
  defaultFilterField: string
): void => {
  const columnField = filterModel?.items?.[0]?.field || defaultFilterField;
  if (!apiRef.current || columnField === undefined) {
    return;
  }
  apiRef.current.showFilterPanel(columnField);
};

export const showColumnManagerPanel = (
  apiRef: React.MutableRefObject<GridApiPro>,
  filterModel: GridFilterModel | undefined,
  defaultFilterField: string
): void => {
  const columnField = filterModel?.items?.[0]?.field || defaultFilterField;
  if (!apiRef.current || columnField === undefined) {
    return;
  }
  apiRef.current.showPreferences(GridPreferencePanelsValue.columns);
};

export const setTableDensity = (
  apiRef: React.MutableRefObject<GridApiPro>,
  density: GridDensity
): void => {
  if (!apiRef.current) {
    return;
  }
  apiRef.current.setDensity(density);
};

export const areFiltersApplied = (
  apiRef: React.MutableRefObject<GridApiPro>
): number => {
  if (!apiRef.current) {
    return 0;
  }
  return apiRef.current.state.filter?.filterModel?.items.filter(
    item => item.value !== undefined && item.value.length > 0
  ).length;
};

export const areFiltersEqual = (
  filterModel1: GridFilterModel | undefined,
  filterModel2: GridFilterModel | undefined
): boolean => {
  if (!filterModel1 && !filterModel2) {
    return true;
  }
  if (
    !filterModel1 &&
    filterModel2?.items.length === 0 &&
    (filterModel2.quickFilterValues?.length === 0 ||
      filterModel2.quickFilterValues?.[0] === "")
  ) {
    return true;
  }
  if (!filterModel1 || !filterModel2) {
    return false;
  }
  const removeFilterIds = (filterModel: GridFilterModel): GridFilterModel => ({
    ...filterModel,
    items: filterModel.items.map(item => {
      const { id, ...rest } = item;
      return rest;
    })
  });
  return (
    JSON.stringify(removeFilterIds(filterModel1)) ===
    JSON.stringify(removeFilterIds(filterModel2))
  );
};

export const getStoredFilterValue = <FieldKey extends keyof FilterValue>(
  fieldKey: FieldKey,
  storage: Storage,
  storageKey: string,
  isNumeric: boolean
): FilterValue[FieldKey] | undefined => {
  const savedState = getStoredState(storage, storageKey);
  const item = savedState?.filter?.filterModel?.items.find(
    f => f.field === fieldKey
  );
  if (!item) {
    return;
  }
  return isNumeric
    ? (numericFilterToObj(item) as FilterValue[FieldKey])
    : item?.value || (isNumeric ? {} : []);
};

export const getModelFilterValue = <FieldKey extends keyof FilterValue>(
  fieldKey: FieldKey,
  filterModel: GridFilterModel | undefined,
  isNumeric: boolean
): FilterValue[FieldKey] | undefined => {
  const item = filterModel?.items.find(f => f.field === fieldKey);
  if (!item) {
    return;
  }
  return isNumeric
    ? (numericFilterToObj(item) as FilterValue[FieldKey])
    : item?.value || (isNumeric ? {} : []);
};

export const getStoredPage = (
  storage: Storage,
  storageKey: string,
  defaultPage?: number
): number => {
  const savedState = getStoredState(storage, storageKey);
  return (
    savedState?.pagination?.paginationModel?.page ||
    defaultPage ||
    defaults.page
  );
};

export const getModelPage = (
  model: GridPaginationModel | undefined,
  defaultPage?: number
): number => {
  return model?.page || defaultPage || defaults.page;
};

export const getStoredPageSize = (
  storage: Storage,
  storageKey: string,
  defaultPageSize?: number
): number => {
  const savedState = getStoredState(storage, storageKey);
  return (
    savedState?.pagination?.paginationModel?.pageSize ||
    defaultPageSize ||
    defaults.pageSize
  );
};

export const getModelPageSize = (
  model: GridPaginationModel | undefined,
  defaultPageSize?: number
): number => {
  return model?.pageSize || defaultPageSize || defaults.pageSize;
};
export const getStoredOrdering = (
  storage: Storage,
  storageKey: string,
  defaultOrdering?: string
): string => {
  const savedState = getStoredState(storage, storageKey);
  return (
    sortModelToString(savedState?.sorting?.sortModel) ||
    defaultOrdering ||
    defaults.ordering
  );
};

export const getModelOrdering = (
  model: GridSortModel | undefined,
  defaultOrdering?: string
): string => {
  return sortModelToString(model) || defaultOrdering || defaults.ordering;
};

export const saveSnapshot = (
  apiRef: React.MutableRefObject<GridApiPro>,
  storage: Storage,
  storageKey: string,
  searchText: string | undefined = undefined
): void => {
  if (apiRef?.current?.exportState && storage) {
    const currentState: GridInitialStatePro = apiRef.current.exportState();
    if (searchText !== undefined && currentState.filter?.filterModel) {
      currentState.filter.filterModel.quickFilterValues = [searchText];
    }
    storage.setItem(storageKey, JSON.stringify(currentState));
  }
};

export const setFilterKeys = (
  textFilters?: string[],
  numericFilters?: string[],
  dateFilters?: string[]
): {
  textFilters: string[];
  numericFilters: string[];
  dateFilters: string[];
} => {
  return {
    textFilters: [...new Set(textFilters)],
    numericFilters: [...new Set(numericFilters)],
    dateFilters: [...new Set(dateFilters)]
  };
};

export const getSavedState = (
  storage: Storage,
  storageKey: string,
  initPage: number,
  initPageSize: number
): {
  filterModel: GridFilterModel | undefined;
  sortModel: GridSortModel | undefined;
  columnVisibilityModel: GridColumnVisibilityModel | undefined;
  paginationModel: GridPaginationModel | undefined;
} => {
  const savedState = getStoredState(storage, storageKey);
  const data = {
    filterModel: undefined as GridFilterModel | undefined,
    sortModel: undefined as GridSortModel | undefined,
    columnVisibilityModel: undefined as GridColumnVisibilityModel | undefined,
    paginationModel: undefined as GridPaginationModel | undefined
  };
  if (savedState?.filter?.filterModel) {
    data.filterModel = savedState.filter.filterModel;
  }
  if (savedState?.sorting?.sortModel) {
    data.sortModel = savedState.sorting.sortModel;
  }
  if (savedState?.columns?.columnVisibilityModel) {
    data.columnVisibilityModel = savedState.columns.columnVisibilityModel;
  }
  if (savedState?.pagination) {
    data.paginationModel = {
      page: savedState.pagination.paginationModel?.page || initPage,
      pageSize: savedState.pagination.paginationModel?.pageSize || initPageSize
    };
  }
  return data;
};

export const setArrowColumnLast = (
  apiRef: React.MutableRefObject<GridApiPro>
): void => {
  const { left, right } = apiRef.current.getPinnedColumns();
  const arrowKey = CommunicationGridHeaders.Arrow;

  if (!right) {
    return;
  }

  if (right.slice(-1)[0] === arrowKey) {
    return;
  }

  apiRef.current.setPinnedColumns({
    left,
    right: [...right.filter(col => col !== arrowKey), arrowKey]
  });
};

export const getItemFromHeaderOrCell = (
  params: GridRenderCellParams | GridCellProps,
  rows?: GridRowsProp
): any => {
  const id =
    (params as GridCellProps)?.rowId || (params as GridRenderCellParams).id;
  return (rows || []).find(row => row.id === id);
};
