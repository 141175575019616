import React, { useState } from "react";

import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { default as MuiMenuItem, MenuItemProps } from "@mui/material/MenuItem";
import {
  GridTableRowsIcon,
  GridViewHeadlineIcon,
  GridViewStreamIcon
} from "@mui/x-data-grid-pro";
import { DotsThreeOutlineVertical, Funnel } from "@phosphor-icons/react";

import { ExternalActionsObj } from "./DataGridCommonUtils";

import Button from "~/mui-components/Button/Button";
import Menu from "~/mui-components/Menu/Menu";
import colors from "~/mui-components/Theme/colors";
import Typography from "~/mui-components/Typography/Typography";

export const Filter: React.FC<{
  dataGridApiRef: ExternalActionsObj | undefined;
}> = ({ dataGridApiRef }) => {
  const filtersApplied = dataGridApiRef?.areFiltersApplied();
  return (
    <Button
      color="secondary"
      className="!min-w-8 md:!min-w-24 [&_.MuiButton-endIcon]:!m-0"
      onClick={() => dataGridApiRef?.showFilterPanel()}
      startIcon={
        <Funnel
          size={20}
          color={colors.teal[30]}
          weight={filtersApplied ? "fill" : "regular"}
        />
      }
    >
      <span className="hidden md:inline">
        Filter {filtersApplied ? `(${filtersApplied})` : ""}
      </span>
    </Button>
  );
};

export const ThreeDotsMenu: React.FC<{
  dataGridApiRef: ExternalActionsObj | undefined;
}> = ({ dataGridApiRef }) => {
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

  const MenuItem = (
    props: MenuItemProps
  ): React.ReactElement<MenuItemProps> => (
    <MuiMenuItem
      sx={{
        color: colors.gray[30],
        "&:hover": {
          backgroundColor: colors.teal[90]
        }
      }}
      {...props}
    />
  );

  return (
    <>
      <IconButton
        aria-describedby="3-dots-menu"
        onClick={e => setAnchor(e.currentTarget)}
      >
        <DotsThreeOutlineVertical
          weight="fill"
          size={20}
          color={colors.teal[40]}
        />
      </IconButton>

      <Menu
        id="3-dots-menu"
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        sx={{
          top: "16px",
          "& .MuiPaper-root": {
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)"
          }
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchor(null);
            dataGridApiRef?.showColumnManagerPanel();
          }}
        >
          Manage Visible Columns
        </MenuItem>
        <Divider component="li" />
        <MuiMenuItem disabled className="!pb-0 !opacity-100 !text-ts-gray-30">
          <Typography variant="caption">Adjust Density</Typography>
        </MuiMenuItem>
        <MenuItem
          onClick={() => {
            setAnchor(null);
            dataGridApiRef?.setDensityCompact();
          }}
        >
          <ListItemIcon>
            <GridViewHeadlineIcon />
          </ListItemIcon>
          <ListItemText>Compact</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchor(null);
            dataGridApiRef?.setDensityStandard();
          }}
        >
          <ListItemIcon>
            <GridTableRowsIcon />
          </ListItemIcon>
          <ListItemText>Standard</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchor(null);
            dataGridApiRef?.setDensityComfortable();
          }}
        >
          <ListItemIcon>
            <GridViewStreamIcon />
          </ListItemIcon>
          <ListItemText>Comfortable</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
