import React, { FunctionComponent } from "react";

import { Trans } from "@lingui/react";
import clsx from "clsx";

import { TermsAndPrivacy } from "../RegisterEmployee/TermsAndPrivacy";

import * as api from "~/api";
import { displayPhoneNumber } from "~/lib/phoneNumber";
import { Loading, MessageActionButton } from "~common";

interface ConfirmPhoneNumberProps {
  className?: string;
  identifyResponse: api.IdentifyEmployeeResponse;
  requesting: boolean;
  onUpdate: () => any;
  onContinue: () => any;
}

export const ConfirmPhoneNumber: FunctionComponent<ConfirmPhoneNumberProps> = ({
  className,
  identifyResponse,
  requesting,
  onUpdate,
  onContinue
}) => {
  const {
    friendly_name: friendlyName,
    inbound_sms_phone_number: phoneNumber,
    company_name: companyName
  } = identifyResponse;

  const displayedPhoneNumber = displayPhoneNumber(phoneNumber);

  return (
    <div
      className={clsx(
        "h-full w-full flex-grow flex flex-col justify-between",
        className
      )}
    >
      <div className="p-9 text-2xl font-bold text-hs-dark-green">
        <p>
          <Trans id="identify.phone.title" values={{ friendlyName }}>
            Hi {friendlyName}!
          </Trans>
        </p>
        <p>
          <Trans
            id="identify.phone.summary"
            values={{ displayedPhoneNumber, companyName }}
          >
            Do you want to save this number ({displayedPhoneNumber}) in
            TeamSense to receive messages from {companyName} going forward?
          </Trans>
        </p>
      </div>
      <div className="bg-hs-light p-9 rounded-t-4xl">
        {requesting && <Loading />}

        <p className="pt-5 pb-3 text-xs text-hs-even-darker-gray">
          <Trans id="register.phone.agreement" values={{ companyName }}>
            By saving your number in TeamSense, you are subscribing to text
            messages from {companyName} powered by TeamSense. You can
            unsubscribe at any time by texting STOP, and text HELP for help.
            Message frequency varies. Message & data rates apply. Neither{" "}
            {companyName} nor TeamSense will share your mobile information with
            third parties/affiliates for marketing/promotional purposes.
          </Trans>
        </p>
        <div className="pt-5 pb-3">
          <MessageActionButton
            theme="dark-green"
            onClick={onUpdate}
            text={<Trans id="identify.phone.confirm">Save number</Trans>}
          />
          <div
            className="mt-6 text-center text-lg underline cursor-pointer text-hs-dark-green"
            onClick={onContinue}
          >
            <Trans id="identify.phone.continueWithoutSaving">
              Continue Without Saving
            </Trans>
          </div>
        </div>
        <div className="flex flex-row justify-center">
          <TermsAndPrivacy />
        </div>
      </div>
    </div>
  );
};
