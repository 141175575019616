import * as React from "react";
import { useContext, useEffect, useLayoutEffect, useState } from "react";

import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridCellProps,
  GridColDef,
  GridColumnMenuProps,
  GridColumnVisibilityModel,
  GridDensity,
  GridFilterModel,
  GridInitialState,
  GridPaginationModel,
  GridRowSelectionModel,
  GridSortModel,
  useGridApiRef
} from "@mui/x-data-grid-pro";

import DataGrid from "../DataGridCommon";
import {
  CustomCellOnClickRow,
  CustomPanelFilter,
  MemorizedColumnMenu,
  MemorizedCustomColumnsPanel,
  MemorizedNoRows
} from "../DataGridCommonSlots";
import {
  areFiltersApplied,
  areFiltersEqual,
  DataGridProps,
  defaults,
  getItemFromHeaderOrCell,
  getModelOrdering,
  getModelPage,
  getModelPageSize,
  getSavedState,
  getStoredState,
  saveSnapshot,
  setArrowColumnLast,
  setFilterKeys,
  setTableDensity,
  showColumnManagerPanel,
  showFilterPanel
} from "../DataGridCommonUtils";

import gridHeaders, {
  CommunicationGridHeaders
} from "./CommunicateGridHeaders";

import { MutableSessionContext } from "~/lib/context/mutableSession";

export const STORAGE_KEY = "communicate-data-grid-state";

const filterKeys = setFilterKeys(["sent_at", "due"]);

const defaultColumnVisibilityModel: GridColumnVisibilityModel = {
  created_by__external_id: false
};

const heightByDensity = {
  compact: 36,
  standard: 52,
  comfortable: 67
};

const CommunicateDataGrid = ({
  searchText,
  onClickRow,
  onSelectRow,
  fetchData,
  initPage = 0,
  initPageSize = defaults.pageSize,
  storageType = defaults.storageType,
  groups,
  setExternalActionsObj
}: DataGridProps & { groups: any[] }): JSX.Element => {
  const apiRef = useGridApiRef();
  const { session } = useContext(MutableSessionContext);
  const [data, setData] = useState<{ rows: any[]; count: number }>();
  const [isLoading, setIsLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: initPage,
    pageSize: initPageSize
  });
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [filterModel, setFilterModel] = useState<GridFilterModel>();
  const [initialState, setInitialState] = useState<GridInitialState>();
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>();
  const [density, setDensity] = useState<GridDensity | undefined>();
  const storage = { LOCAL: localStorage, SESSION: sessionStorage }[storageType];

  const callFetchData = async (options: any = {}): Promise<void> => {
    try {
      setIsLoading(true);
      const response = await fetchData(options);
      if (response) {
        setData({
          rows: response.data,
          count: response.count
        });
      }
    } catch (error) {
      console.error("Error while fetching data", error);
      setData(defaults.data);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    callFetchData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onFilterModelChange({
      ...(filterModel || { items: [] }),
      quickFilterValues: [searchText]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const fetchDataFilters = ({
    fm,
    sm,
    pm
  }: {
    fm?: GridFilterModel;
    sm?: GridSortModel;
    pm?: GridPaginationModel;
  }): void => {
    callFetchData({
      page: getModelPage(pm || paginationModel) + 1,
      limit: getModelPageSize(pm || paginationModel, initPageSize),
      ordering: getModelOrdering(sm || sortModel)
    });
  };

  useEffect(() => {
    saveSnapshot(apiRef, storage, STORAGE_KEY, searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterModel, sortModel, paginationModel, columnVisibilityModel]);

  useEffect(() => {
    if (apiRef && setExternalActionsObj) {
      const externalFunctions = {
        showFilterPanel: (): void =>
          showFilterPanel(apiRef, filterModel, filterKeys.textFilters[0]),
        showColumnManagerPanel: (): void =>
          showColumnManagerPanel(
            apiRef,
            filterModel,
            filterKeys.textFilters[0]
          ),
        setDensityCompact: (): void => {
          setTableDensity(apiRef, "compact");
          setDensity("compact");
        },
        setDensityStandard: (): void => {
          setTableDensity(apiRef, "standard");
          setDensity("standard");
        },
        setDensityComfortable: (): void => {
          setTableDensity(apiRef, "comfortable");
          setDensity("comfortable");
        },
        reloadData: () => callFetchData({}),
        areFiltersApplied: () => areFiltersApplied(apiRef)
      };
      setExternalActionsObj(externalFunctions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRef, filterModel, setExternalActionsObj]);

  useLayoutEffect(() => {
    const savedState = getStoredState(storage, STORAGE_KEY);
    setInitialState(savedState || {});
    const { filterModel, sortModel, columnVisibilityModel, paginationModel } =
      getSavedState(storage, STORAGE_KEY, initPage, initPageSize);
    if (filterModel) {
      setFilterModel(filterModel);
    }
    if (sortModel) {
      setSortModel(sortModel);
    }
    if (columnVisibilityModel) {
      setColumnVisibilityModel(columnVisibilityModel);
    } else {
      setColumnVisibilityModel(defaultColumnVisibilityModel);
    }
    if (paginationModel) {
      setPaginationModel(paginationModel);
    }
    return () => saveSnapshot(apiRef, storage, STORAGE_KEY, searchText);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storage]);

  const columns: GridColDef[] = React.useMemo(
    () =>
      [
        gridHeaders(CommunicationGridHeaders.Status),
        gridHeaders(CommunicationGridHeaders.SendDate),
        gridHeaders(CommunicationGridHeaders.Subject),
        gridHeaders(CommunicationGridHeaders.MessageBody),
        gridHeaders(CommunicationGridHeaders.Recipients, {
          session,
          groups
        }),

        gridHeaders(CommunicationGridHeaders.SenderName),
        gridHeaders(CommunicationGridHeaders.SenderId),
        gridHeaders(CommunicationGridHeaders.Arrow)
      ].filter(Boolean) as GridColDef[],
    [session, groups]
  );

  const onPaginationChange = (model: GridPaginationModel): void => {
    const newPaginationModel: GridPaginationModel = {
      ...paginationModel,
      ...model
    };
    setPaginationModel(newPaginationModel);
    fetchDataFilters({ pm: newPaginationModel });
  };

  const getTogglableColumns = (columns: GridColDef[]): string[] =>
    columns
      .filter(
        column =>
          ![
            GRID_CHECKBOX_SELECTION_COL_DEF.field,
            CommunicationGridHeaders.Arrow
          ].includes(column.field)
      )
      .map(column => column.field);

  const onSortModelChange = (sortModel: GridSortModel): void => {
    setSortModel(sortModel);
    fetchDataFilters({ sm: sortModel });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onFilterModelChange = (newFilterModel: GridFilterModel): void => {
    if (areFiltersEqual(filterModel, newFilterModel)) {
      return;
    }
    setFilterModel(newFilterModel);
    fetchDataFilters({ fm: newFilterModel });
  };

  const onColumnVisibilityModelChange = (
    newColumnVisibilityModel: GridColumnVisibilityModel
  ): void => {
    setColumnVisibilityModel(newColumnVisibilityModel);
  };

  const onRowSelectionModelChange = (
    newRowSelectionModel: GridRowSelectionModel
  ): void => {
    onSelectRow?.(newRowSelectionModel as string[]);
  };

  const memorizedSlots = React.useMemo(
    () => ({
      columnMenu: (props: GridColumnMenuProps): JSX.Element => (
        <MemorizedColumnMenu {...props} />
      ),
      noResultsOverlay: () => <MemorizedNoRows />,
      noRowsOverlay: () => <MemorizedNoRows />,
      columnsPanel: () => <MemorizedCustomColumnsPanel />
    }),
    []
  );

  const slots = {
    filterPanel: () => (
      <CustomPanelFilter
        selectedFilters={filterModel?.items?.map(item => item.field) || []}
        allFilters={[...filterKeys.textFilters, ...filterKeys.numericFilters]}
        showRemoveAllButton={filterModel?.items?.length !== 0}
      />
    ),
    cell: (props: GridCellProps): JSX.Element => (
      <CustomCellOnClickRow
        {...props}
        onClick={() => {
          const message = getItemFromHeaderOrCell(props, data?.rows);
          if (message && onClickRow) {
            onClickRow(message);
          }
        }}
        extraClassNames={["communicate-open-modal"]}
      />
    )
  };

  return (
    <DataGrid
      // API
      apiRef={apiRef}
      // Data
      rows={data?.rows || []}
      columns={columns}
      rowCount={data?.count || 0}
      // Custom components
      slots={{
        ...memorizedSlots,
        ...slots
      }}
      slotProps={{
        loadingOverlay: {
          variant: "skeleton",
          noRowsVariant: "skeleton"
        },
        columnsManagement: {
          getTogglableColumns
        }
      }}
      // Sorting
      sortModel={sortModel}
      sortingMode="server"
      onSortModelChange={onSortModelChange}
      // Filtering
      filterMode="server"
      filterModel={filterModel}
      onFilterModelChange={onFilterModelChange}
      // Column visibility
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={onColumnVisibilityModelChange}
      // Pagination
      pagination
      paginationMode="server"
      pageSizeOptions={[10, 25, 50, 100, 500, 1000]}
      paginationModel={paginationModel}
      onPaginationModelChange={onPaginationChange}
      // Clicking
      disableRowSelectionOnClick
      // Selection
      rowSelection
      onRowSelectionModelChange={onRowSelectionModelChange}
      // Pinned columns
      onPinnedColumnsChange={() => setArrowColumnLast(apiRef)}
      // Status
      loading={isLoading || initialState === undefined}
      initialState={{
        ...initialState,
        pinnedColumns: {
          left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
          right: [CommunicationGridHeaders.Arrow]
        }
      }}
      disableColumnPinning
      density={density || initialState?.density || defaults.density}
      // Extra props
      getRowHeight={() => "auto"}
      sx={{
        "div[role='gridcell']": {
          minHeight: heightByDensity[density || "standard"]
        }
      }}
    />
  );
};

export default CommunicateDataGrid;
