import * as React from "react";
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";

import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridApiPro,
  GridCellProps,
  GridColDef,
  GridColumnMenuProps,
  GridColumnVisibilityModel,
  GridDensity,
  GridFilterItem,
  GridFilterModel,
  GridInitialState,
  GridPaginationModel,
  GridPreferencePanelsValue,
  GridRowSelectionModel,
  GridSortItem,
  GridSortModel,
  useGridApiRef
} from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";

import DataGrid from "../DataGridCommon";
import {
  CustomPanelFilter,
  MemorizedCellRow,
  MemorizedColumnMenu,
  MemorizedCustomColumnsPanel,
  MemorizedNoRows
} from "../DataGridCommonSlots";
import { areFiltersApplied, ExternalActionsObj } from "../DataGridCommonUtils";

import gridHeaders, { EmployeeTableHeaders } from "./GridHeaders";

import * as api from "~/api";
import { MutableSessionContext } from "~/lib/context/mutableSession";
import { FilterListItem } from "~/lib/filterList";
import { useLinguiLanguage } from "~/lib/hooks";

export const STORAGE_KEY = "dataGridState";

type EmployeesTableProps = {
  deactivatedMode: boolean;
  searchText?: string;
  groups: api.Group[];
  managers: api.IdentificationEmployee[];
  statuses: FilterListItem[];
  roles: FilterListItem[];
  divisions: FilterListItem[];
  onSelectEmployees?: (selectedEmployeeIds: api.Employee["id"][]) => void;
  onEditStatusClick?: (employee: api.Employee, labelKey: api.AnyStatus) => void;
  onReactivateClick?: (employee: api.Employee) => void;
  initPage?: number;
  initPageSize?: number;
  storageType?: "LOCAL" | "SESSION";
  setExternalActionsObj?: (externalActionObject: ExternalActionsObj) => void;
};

type FilterValue = {
  team_lead: string[];
  division: string[];
  groups: string[];
  active_events: string[];
  role: string[];
  attendance_points: { [key: string]: string };
  sick_points: { [key: string]: string };
  pto_points: { [key: string]: string };
  vacation_points: { [key: string]: string };
  flex_points: { [key: string]: string };
  other_points: { [key: string]: string };
};

const getStoredState = (storage: Storage): GridInitialStatePro => {
  const gridData = storage?.getItem(STORAGE_KEY);
  if (gridData) {
    try {
      return JSON.parse(gridData);
    } catch (e) {
      console.error(e);
    }
  }
  return {};
};

const sortModelToString = (
  sortModel: GridSortModel | undefined
): string | undefined => {
  return sortModel
    ?.map((prop: GridSortItem) => {
      return `${prop.sort === "asc" ? "" : "-"}${prop.field}`;
    })
    .join(",")
    .replaceAll("team_lead", "team_lead__name")
    .replaceAll("active_events", "active_event_label_name")
    .replaceAll("division", "division__name");
};

const numericFilterToObj = (
  filter: GridFilterItem | undefined
): { [key: string]: string } => {
  if (!filter) {
    return {};
  }
  const { field, operator, value } = filter;
  if (!field || !operator) {
    return {};
  }
  const operatorDir = {
    "=": "exact",
    ">": "gt",
    "<": "lt",
    ">=": "gte",
    "<=": "lte",
    "!=": "ne"
  } as any;
  const operatorValue = {
    isEmpty: "True",
    isNotEmpty: "False"
  } as any;
  if (operator in operatorValue) {
    return { [`${field}__isnull`]: `${operatorValue[operator]}` };
  }
  if (value === undefined) {
    return {};
  }
  return { [`${field}__${operatorDir[operator]}`]: `${value}` };
};

const showFilterPanel = (
  apiRef: React.MutableRefObject<GridApiPro>,
  filterModel: GridFilterModel | undefined,
  deactivatedMode: boolean
): void => {
  const availableTextFilters = deactivatedMode
    ? textFiltersForDeactivated
    : textFilters;
  const columnField = filterModel?.items?.[0]?.field || availableTextFilters[0];
  if (!apiRef.current || columnField === undefined) {
    return;
  }
  apiRef.current.showFilterPanel(columnField);
};

const showColumnManagerPanel = (
  apiRef: React.MutableRefObject<GridApiPro>,
  filterModel: GridFilterModel | undefined
): void => {
  const columnField = filterModel?.items?.[0]?.field || textFilters[0];
  if (!apiRef.current || columnField === undefined) {
    return;
  }
  apiRef.current.showPreferences(GridPreferencePanelsValue.columns);
};

const setTableDensity = (
  apiRef: React.MutableRefObject<GridApiPro>,
  density: GridDensity
): void => {
  if (!apiRef.current) {
    return;
  }
  apiRef.current.setDensity(density);
};

const areFiltersEqual = (
  filterModel1: GridFilterModel | undefined,
  filterModel2: GridFilterModel | undefined
): boolean => {
  if (!filterModel1 && !filterModel2) {
    return true;
  }
  if (
    !filterModel1 &&
    filterModel2?.items.length === 0 &&
    (filterModel2.quickFilterValues?.length === 0 ||
      filterModel2.quickFilterValues?.[0] === "")
  ) {
    return true;
  }
  if (!filterModel1 || !filterModel2) {
    return false;
  }
  const removeFilterIds = (filterModel: GridFilterModel): GridFilterModel => ({
    ...filterModel,
    items: filterModel.items.map(item => {
      const { id, ...rest } = item;
      return rest;
    })
  });
  return (
    JSON.stringify(removeFilterIds(filterModel1)) ===
    JSON.stringify(removeFilterIds(filterModel2))
  );
};

const textFilters = [
  "active_events",
  "division",
  "role",
  "team_lead",
  "groups"
];

const textFiltersForDeactivated = textFilters.filter(
  f => f !== "active_events"
);

const numericFilters = [
  "attendance_points",
  "sick_points",
  "pto_points",
  "vacation_points",
  "flex_points",
  "other_points"
];

const getAvailableBalanceHeaders = (
  balances: api.BalanceLabel[]
): GridColDef[] => {
  const headers = [];
  const dirObj: Record<string, (balance: api.BalanceLabel) => GridColDef> = {
    attendance_points: balance =>
      gridHeaders(EmployeeTableHeaders.AttendancePoints, {
        headerName: balance.name,
        balanceUnit: balance.unit
      }),
    sick: balance =>
      gridHeaders(EmployeeTableHeaders.SickPoints, {
        headerName: balance.name,
        balanceUnit: balance.unit
      }),
    pto: balance =>
      gridHeaders(EmployeeTableHeaders.PtoPoints, {
        headerName: balance.name,
        balanceUnit: balance.unit
      }),
    vacation: balance =>
      gridHeaders(EmployeeTableHeaders.VacationPoints, {
        headerName: balance.name,
        balanceUnit: balance.unit
      }),
    flex: balance =>
      gridHeaders(EmployeeTableHeaders.FlexPoints, {
        headerName: balance.name,
        balanceUnit: balance.unit
      }),
    other: balance =>
      gridHeaders(EmployeeTableHeaders.OtherPoints, {
        headerName: balance.name,
        balanceUnit: balance.unit
      })
  };
  for (const balance of balances) {
    if (Object.keys(dirObj).includes(balance.kind)) {
      headers.push(dirObj[balance.kind](balance));
    }
  }
  return headers;
};

const EmployeesTable = ({
  deactivatedMode,
  searchText,
  managers,
  groups,
  statuses,
  roles,
  divisions,
  onSelectEmployees,
  onEditStatusClick,
  onReactivateClick,
  initPage = 0,
  initPageSize = 100,
  storageType = "SESSION",
  setExternalActionsObj
}: EmployeesTableProps): JSX.Element => {
  const apiRef = useGridApiRef();
  const { session } = useContext(MutableSessionContext);
  const language = useLinguiLanguage();
  const [displayList, setDisplayList] = useState<{
    employees: api.Employee[];
    count: number;
  }>({
    employees: [],
    count: 0
  });
  const [isLoading, setIsLoading] = useState(false);

  const rows = useMemo(() => displayList.employees, [displayList.employees]);
  const rowCount = useMemo(() => displayList.count || 0, [displayList.count]);

  const [paginationModel, setPaginationModel] = useState({
    page: initPage,
    pageSize: initPageSize
  });
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [filterModel, setFilterModel] = useState<GridFilterModel>();
  const [initialState, setInitialState] = useState<GridInitialState>();
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>();
  const [density, setDensity] = useState<GridDensity | undefined>();
  const [availableBalances, setAvailableBalances] = useState<
    api.BalanceLabel[]
  >([]);
  const defaultDensity = "compact";
  const prevDeactivatedMode = useRef(deactivatedMode);
  const prevSearchText = useRef(searchText);
  const storage = { LOCAL: localStorage, SESSION: sessionStorage }[storageType];

  const saveSnapshot = (
    newSearchText: string | undefined = undefined
  ): void => {
    if (apiRef?.current?.exportState && storage) {
      const currentState: GridInitialStatePro = apiRef.current.exportState();
      if (newSearchText !== undefined && currentState.filter?.filterModel) {
        currentState.filter.filterModel.quickFilterValues = [newSearchText];
      }
      storage.setItem(STORAGE_KEY, JSON.stringify(currentState));
    }
  };

  const getFilterValue = <Key extends keyof FilterValue>(
    key: Key,
    useFilterModel: GridFilterModel | "current" | "saved"
  ): FilterValue[Key] | undefined => {
    const isNumeric = numericFilters.includes(key);
    const defaultValue = (isNumeric ? {} : []) as FilterValue[Key];
    let item;

    if (useFilterModel === "saved") {
      const savedState = getStoredState(storage);
      item = savedState?.filter?.filterModel?.items.find(f => f.field === key);
    } else if (useFilterModel === "current") {
      item = filterModel?.items.find(f => f.field === key);
    } else if (useFilterModel) {
      item = useFilterModel?.items.find(f => f.field === key);
    }

    if (!item) {
      return;
    }
    return isNumeric
      ? (numericFilterToObj(item) as FilterValue[Key])
      : item?.value || defaultValue;
  };

  const getOrdering = (
    useSortModel: GridSortModel | "current" | "saved"
  ): string => {
    if (useSortModel === "saved") {
      const savedState = getStoredState(storage);
      return sortModelToString(savedState?.sorting?.sortModel) || "";
    }
    if (useSortModel === "current") {
      return sortModelToString(sortModel) || "";
    }
    if (useSortModel) {
      return sortModelToString(useSortModel) || "";
    }
    return "";
  };

  const getPage = (
    usePaginationModel: GridPaginationModel | "current" | "saved"
  ): number => {
    if (usePaginationModel === "saved") {
      const savedState = getStoredState(storage);
      return savedState?.pagination?.paginationModel?.page || 0;
    }
    if (usePaginationModel === "current") {
      return paginationModel.page;
    }
    if (usePaginationModel) {
      return usePaginationModel.page;
    }
    return 0;
  };

  const getPageLimit = (
    usePaginationModel: GridPaginationModel | "current" | "saved"
  ): number => {
    if (usePaginationModel === "saved") {
      const savedState = getStoredState(storage);
      return savedState?.pagination?.paginationModel?.pageSize || initPageSize;
    }
    if (usePaginationModel === "current") {
      return paginationModel.pageSize;
    }
    if (usePaginationModel) {
      return usePaginationModel.pageSize;
    }
    return initPageSize;
  };

  const fetchEmployees = async (
    options: api.RetrieveEmployeeOptions
  ): Promise<void> => {
    try {
      setIsLoading(true);
      const response = await api.retrieveEmployees(
        session.company?.id || "",
        {
          activeEvents: true,
          inactiveOnly: deactivatedMode,
          groups: true,
          ...(searchText ? { nameOrId: searchText } : {}),
          ...options
        },
        true
      );
      if (response.ok) {
        setDisplayList({
          employees: response.data.results,
          count: response.data.count || 0
        });
      } else {
        setDisplayList({
          employees: [],
          count: 0
        });
      }
    } catch (error) {
      console.error("Error while filtering employees", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBalanceLabels = async (): Promise<void> => {
    try {
      const response = await api.retrieveBalanceLabels(
        session.company?.id || ""
      );
      if (response.ok) {
        setAvailableBalances(response.data);
      }
    } catch (error) {
      console.error("Error while fetching balance labels", error);
    }
  };

  useEffect(() => {
    fetchEmployees({
      teamLeadIds: getFilterValue("team_lead", "saved"),
      roles: getFilterValue("role", "saved"),
      groupIds: getFilterValue("groups", "saved"),
      divisionIds: getFilterValue("division", "saved"),
      statuses: getFilterValue("active_events", "saved"),
      attendancePoints: getFilterValue("attendance_points", "saved"),
      sickPoints: getFilterValue("sick_points", "saved"),
      ptoPoints: getFilterValue("pto_points", "saved"),
      vacationPoints: getFilterValue("vacation_points", "saved"),
      flexPoints: getFilterValue("flex_points", "saved"),
      otherPoints: getFilterValue("other_points", "saved"),
      ordering: getOrdering("saved"),
      page: getPage("saved") + 1,
      limit: getPageLimit("saved"),
      ...(searchText ? { nameOrId: searchText } : {})
    });
    fetchBalanceLabels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (prevSearchText.current !== searchText) {
      onFilterModelChange({
        ...(filterModel || { items: [] }),
        quickFilterValues: [searchText]
      });
      prevSearchText.current = searchText;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    if (prevDeactivatedMode.current !== deactivatedMode) {
      fetchEmployeesWithFilters({});
      prevDeactivatedMode.current = deactivatedMode;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deactivatedMode]);

  const fetchEmployeesWithFilters = ({
    fm,
    sm,
    pm
  }: {
    fm?: GridFilterModel;
    sm?: GridSortModel;
    pm?: GridPaginationModel;
  }): void => {
    fetchEmployees({
      teamLeadIds: getFilterValue("team_lead", fm || "current"),
      divisionIds: getFilterValue("division", fm || "current"),
      groupIds: getFilterValue("groups", fm || "current"),
      statuses: getFilterValue("active_events", fm || "current"),
      roles: getFilterValue("role", fm || "current"),
      attendancePoints: getFilterValue("attendance_points", fm || "current"),
      sickPoints: getFilterValue("sick_points", fm || "current"),
      ptoPoints: getFilterValue("pto_points", fm || "current"),
      vacationPoints: getFilterValue("vacation_points", fm || "current"),
      flexPoints: getFilterValue("flex_points", fm || "current"),
      otherPoints: getFilterValue("other_points", fm || "current"),
      ordering: getOrdering(sm || "current"),
      page: getPage(pm || "current") + 1,
      limit: getPageLimit(pm || "current")
    });
  };

  useEffect(() => {
    saveSnapshot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterModel, sortModel, paginationModel, columnVisibilityModel]);

  useEffect(() => {
    if (apiRef && setExternalActionsObj) {
      const externalFunctions = {
        showFilterPanel: (): void =>
          showFilterPanel(apiRef, filterModel, deactivatedMode),
        showColumnManagerPanel: (): void =>
          showColumnManagerPanel(apiRef, filterModel),
        setDensityCompact: (): void => {
          setTableDensity(apiRef, "compact");
          setDensity("compact");
        },
        setDensityStandard: (): void => {
          setTableDensity(apiRef, "standard");
          setDensity("standard");
        },
        setDensityComfortable: (): void => {
          setTableDensity(apiRef, "comfortable");
          setDensity("comfortable");
        },
        areFiltersApplied: () => areFiltersApplied(apiRef),
        getData: (): api.Employee[] => displayList.employees
      };
      setExternalActionsObj(externalFunctions);
    }
  }, [
    apiRef,
    filterModel,
    setExternalActionsObj,
    displayList.employees,
    deactivatedMode
  ]);

  useLayoutEffect(() => {
    const savedState = getStoredState(storage);
    setInitialState(savedState || {});
    if (savedState?.filter?.filterModel) {
      setFilterModel(savedState.filter.filterModel);
    }
    if (savedState?.sorting?.sortModel) {
      setSortModel(savedState.sorting.sortModel);
    }
    if (savedState?.columns?.columnVisibilityModel) {
      setColumnVisibilityModel(savedState.columns.columnVisibilityModel);
    } else {
      setColumnVisibilityModel({
        attendance_points: false,
        flex_points: false,
        other_points: false,
        pto_points: false,
        sick_points: false,
        vacation_points: false
      });
    }
    if (savedState?.pagination) {
      setPaginationModel({
        page: savedState.pagination.paginationModel?.page || 0,
        pageSize:
          savedState.pagination.paginationModel?.pageSize || initPageSize
      });
    }
    return () => saveSnapshot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storage]);

  const columns: GridColDef[] = React.useMemo(
    () =>
      [
        gridHeaders(EmployeeTableHeaders.FirstName),
        gridHeaders(EmployeeTableHeaders.LastName),
        gridHeaders(EmployeeTableHeaders.ExternalId),
        ...[
          !deactivatedMode &&
            gridHeaders(EmployeeTableHeaders.Status, {
              session,
              language,
              onEditStatusClick,
              options: statuses
            })
        ],
        ...getAvailableBalanceHeaders(availableBalances),
        gridHeaders(EmployeeTableHeaders.Role, {
          options: roles
        }),
        gridHeaders(EmployeeTableHeaders.Division, {
          options: divisions
        }),
        gridHeaders(EmployeeTableHeaders.Manager, {
          options: managers
        }),
        ...[
          !deactivatedMode &&
            gridHeaders(EmployeeTableHeaders.Groups, {
              options: groups
            })
        ],
        ...[
          deactivatedMode &&
            gridHeaders(EmployeeTableHeaders.Reactivate, {
              onReactivateClick
            })
        ],
        ...[!deactivatedMode && gridHeaders(EmployeeTableHeaders.Arrow)]
      ].filter(Boolean) as GridColDef[],
    [
      session,
      language,
      onEditStatusClick,
      statuses,
      roles,
      divisions,
      managers,
      groups,
      deactivatedMode,
      onReactivateClick,
      availableBalances
    ]
  );

  const onPaginationChange = (model: GridPaginationModel): void => {
    const newPaginationModel: GridPaginationModel = {
      ...paginationModel,
      ...model
    };
    setPaginationModel(newPaginationModel);
    fetchEmployeesWithFilters({ pm: newPaginationModel });
  };

  const getTogglableColumns = (columns: GridColDef[]): string[] =>
    columns
      .filter(
        column =>
          ![
            GRID_CHECKBOX_SELECTION_COL_DEF.field,
            EmployeeTableHeaders.Arrow,
            EmployeeTableHeaders.Reactivate
          ].includes(column.field)
      )
      .map(column => column.field);

  const onSortModelChange = (sortModel: GridSortModel): void => {
    setSortModel(sortModel);
    fetchEmployeesWithFilters({ sm: sortModel });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onFilterModelChange = (newFilterModel: GridFilterModel): void => {
    if (areFiltersEqual(filterModel, newFilterModel)) {
      return;
    }
    setFilterModel(newFilterModel);
    fetchEmployeesWithFilters({ fm: newFilterModel });
  };

  const onColumnVisibilityModelChange = (
    newColumnVisibilityModel: GridColumnVisibilityModel
  ): void => {
    setColumnVisibilityModel(newColumnVisibilityModel);
  };

  const onRowSelectionModelChange = (
    newRowSelectionModel: GridRowSelectionModel
  ): void => {
    onSelectEmployees?.(newRowSelectionModel as string[]);
  };

  const memorizedSlots = React.useMemo(
    () => ({
      cell: (props: GridCellProps): JSX.Element => (
        <MemorizedCellRow {...props} clickable={!deactivatedMode} />
      ),
      columnMenu: (props: GridColumnMenuProps): JSX.Element => (
        <MemorizedColumnMenu {...props} />
      ),
      noResultsOverlay: () => <MemorizedNoRows />,
      noRowsOverlay: () => <MemorizedNoRows />,
      columnsPanel: () => <MemorizedCustomColumnsPanel />
    }),
    [deactivatedMode]
  );

  const slots = {
    filterPanel: () => (
      <CustomPanelFilter
        selectedFilters={filterModel?.items?.map(item => item.field) || []}
        allFilters={
          deactivatedMode
            ? [...textFiltersForDeactivated, ...numericFilters]
            : [...textFilters, ...numericFilters]
        }
        showRemoveAllButton={filterModel?.items?.length !== 0}
      />
    )
  };

  const setArrowColumnLast = (
    apiRef: React.MutableRefObject<GridApiPro>
  ): void => {
    const { left, right } = apiRef.current.getPinnedColumns();
    const arrowKey = EmployeeTableHeaders.Arrow;

    if (!right) {
      return;
    }

    if (right.slice(-1)[0] === arrowKey) {
      return;
    }

    apiRef.current.setPinnedColumns({
      left,
      right: [...right.filter(col => col !== arrowKey), arrowKey]
    });
  };

  return (
    <DataGrid
      // API
      apiRef={apiRef}
      // Data
      rows={rows}
      columns={columns}
      rowCount={rowCount}
      // Custom components
      slots={{
        ...memorizedSlots,
        ...slots
      }}
      slotProps={{
        loadingOverlay: {
          variant: "skeleton",
          noRowsVariant: "skeleton"
        },
        columnsManagement: {
          getTogglableColumns
        }
      }}
      // Sorting
      sortModel={sortModel}
      sortingMode="server"
      onSortModelChange={onSortModelChange}
      // Filtering
      filterMode="server"
      filterModel={filterModel}
      onFilterModelChange={onFilterModelChange}
      // Column visibility
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={onColumnVisibilityModelChange}
      // Pagination
      pagination
      paginationMode="server"
      pageSizeOptions={[10, 25, 50, 100, 500, 1000]}
      paginationModel={paginationModel}
      onPaginationModelChange={onPaginationChange}
      // Clicking
      getRowClassName={() => (!deactivatedMode ? "cursor-pointer" : "")}
      disableRowSelectionOnClick
      // Selection
      rowSelection
      checkboxSelection={!deactivatedMode}
      // TODO: Should general select, select all database rows
      onRowSelectionModelChange={onRowSelectionModelChange}
      // Pinned columns
      onPinnedColumnsChange={() => setArrowColumnLast(apiRef)}
      // Status
      loading={isLoading || initialState === undefined}
      initialState={{
        ...initialState,
        pinnedColumns: {
          left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
          right: [EmployeeTableHeaders.Arrow]
        }
      }}
      disableColumnPinning
      density={density || initialState?.density || defaultDensity}
    />
  );
};

export default EmployeesTable;
