export interface FilterListItem {
  id: string;
  name: string;
}

export interface SelectedItem<T extends FilterListItem> {
  selected: boolean;
  item: T;
}

export interface SelectedItems<T extends FilterListItem> {
  [key: string]: SelectedItem<T>;
}

export interface FilterList<T extends FilterListItem> {
  displayEmpty: boolean;
  items: SelectedItems<T>;
}

export const areAllSelected: <T extends FilterListItem>(
  list: FilterList<T>,
  displayNone: boolean
) => boolean = (list, displayNone) => {
  if (displayNone && !list.displayEmpty) {
    return false;
  }
  return Object.keys(list.items).reduce(
    (val: boolean, key: string): boolean => val && list.items[key].selected,
    true
  );
};

export const filterIdsFromSelectedItems: <T extends FilterListItem>(
  list: SelectedItems<T>
) => string[] = items =>
  Object.keys(items).reduce((ids: string[], id: string): string[] => {
    if (items[id].selected) {
      ids.push(id);
    }
    return ids;
  }, []);

export const selectedCount: <T extends FilterListItem>(
  list: FilterList<T>,
  displayNone: boolean
) => number = (list, displayNone) =>
  Object.keys(list.items).reduce(
    (val: number, key: string): number =>
      val + (list.items[key].selected ? 1 : 0),
    displayNone && list.displayEmpty ? 1 : 0
  );

export enum SessionKey {
  attendanceDivisionFilter = "ATTENDANCE:DIVISION_FILTER",
  employeesStatusFilter = "EMPLOYEES_PANE:STATUS_FILTER",
  employeesSearchFilter = "EMPLOYEES_PANE:SEARCH_FILTER",
  employeesDivisionFilter = "EMPLOYEES_PANE:DIVISION_FILTER",
  employeesGroupFilter = "EMPLOYEES_PANE:GROUP_FILTER",
  employeesManagerFilter = "EMPLOYEES_PANE:MANAGER_FILTER",
  employeesRoleFilter = "EMPLOYEES_PANE:ROLE_FILTER",
  employeesSorting = "EMPLOYEES_PANE:SORTING"
}

interface PersistedFilterData {
  ids: string[];
  none: boolean;
}

export const getPersistedFilterData = (
  sessionKey: SessionKey
): PersistedFilterData | undefined => {
  const persistedData = sessionStorage.getItem(sessionKey);
  if (persistedData) {
    return JSON.parse(persistedData);
  }
};

export const persistFilterList = (
  filterList: FilterList<FilterListItem>,
  filterIds: string[],
  displayNone: boolean,
  sessionKey: SessionKey
): void => {
  const none = filterList.displayEmpty;
  const selectAll = areAllSelected(filterList, displayNone);
  if (selectAll) {
    sessionStorage.removeItem(sessionKey);
  } else {
    sessionStorage.setItem(
      sessionKey,
      JSON.stringify({ ids: filterIds, none })
    );
  }
};

export const updateFilterList = (
  filterList: FilterList<FilterListItem>,
  newData: FilterListItem[],
  sessionKey?: SessionKey
): FilterList<FilterListItem> => {
  const restoredData = sessionKey
    ? getPersistedFilterData(sessionKey)
    : undefined;
  return {
    displayEmpty: restoredData?.none ?? filterList.displayEmpty,
    items: newData.reduce((dict, filterListItem) => {
      dict[filterListItem.id] =
        filterList.items[filterListItem.id] === undefined
          ? {
              selected: restoredData?.ids.includes(filterListItem.id) ?? true,
              item: filterListItem
            }
          : filterList.items[filterListItem.id];
      return dict;
    }, {} as SelectedItems<FilterListItem>)
  };
};
