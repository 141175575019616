import React from "react";

import {
  getGridNumericOperators,
  getGridSingleSelectOperators,
  GridColDef,
  GridFilterOperator
} from "@mui/x-data-grid-pro";

import { MemorizedCustomRightArrow } from "./DataGridCommonSlots";

export const splitCamelCase = (str: string): string => {
  return str
    .split(/_|-/g)
    .filter(Boolean)
    .map(s => s.charAt(0).toUpperCase() + s.slice(1))
    .join(" ");
};

const getOperatorsCustomNames = (
  operator: GridFilterOperator
): GridFilterOperator => {
  if (!operator.value) {
    return operator;
  }
  const labelMap: { [key: string]: string } = {
    is: "is",
    isAnyOf: "is any of",
    isEmpty: "is empty",
    isNotEmpty: "is not empty",
    "=": "equals",
    "!=": "does not equal",
    ">": "is greater than",
    ">=": "is greater than or equal",
    "<": "is less than",
    "<=": "is less than or equal"
  };

  return {
    ...operator,
    label: labelMap[operator.value] || operator.label || operator.value
  };
};

export const selectOperators = getGridSingleSelectOperators()
  .map(getOperatorsCustomNames)
  .filter(operator => ["is", "isAnyOf"].includes(operator.value))
  .reverse();

export const numericOperators = getGridNumericOperators()
  .map(getOperatorsCustomNames)
  .filter(operator =>
    ["=", "!=", ">", ">=", "<", "<=", "isEmpty", "isNotEmpty"].includes(
      operator.value
    )
  );

export const basicHeaderProps: Omit<GridColDef, "type" | "field"> = {
  renderCell: params => {
    const { value } = params;
    return value;
  },
  minWidth: 80,
  width: 100,
  flex: 1,
  filterable: true,
  sortable: true
};

export const getHeader = (
  headerName: string,
  headers: { [key: string]: GridColDef }
): GridColDef => {
  const header = headers[headerName];
  if (!header) {
    return {
      field: headerName,
      headerName,
      renderCell: params => {
        const { value } = params;
        return value;
      }
    };
  }
  return headers[headerName];
};

export const arrow: GridColDef = {
  ...basicHeaderProps,
  minWidth: 40,
  maxWidth: 40,
  field: "_arrow",
  headerName: "",
  type: "custom",
  sortable: false,
  filterable: false,
  hideable: false,
  disableColumnMenu: true,
  groupable: false,
  renderCell: () => <MemorizedCustomRightArrow />
};

export const gridHeaders = (headerName: string, props?: any): GridColDef => {
  return getHeader(headerName, {});
};

export default gridHeaders;
