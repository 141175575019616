import colors from "../Theme/colors";
import { MuiElementTheme } from "../Theme/types";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    multipleChoice: true;
  }
}

const MultipleChoiceButtonTheme: MuiElementTheme<"MuiButton"> = {
  variants: [
    {
      props: { variant: "multipleChoice" },
      style: {
        color: colors.gray[30],
        minHeight: "2.75rem",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: colors.gray[40],
        borderRadius: "0.25rem !important",
        "&.selected": {
          borderColor: colors.teal[30],
          backgroundColor: colors.teal[80],
          color: colors.teal[30]
        }
      }
    }
  ]
};

export default MultipleChoiceButtonTheme;
