import React, {
  FunctionComponent,
  useState,
  useEffect,
  useContext
} from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { isEqual } from "lodash";

import { RadioButton } from "../RadioButton/RadioButton";

import * as api from "~/api";
import NotificationAllCompany from "~/images/notification-all-company.svg";
import NotificationAllDivisions from "~/images/notification-all-divisions.svg";
import NotificationDirectReports from "~/images/notification-direct-reports.svg";
import NotificationExtendedDirect from "~/images/notification-extended-direct.svg";
import NotificationNone from "~/images/notification-none.svg";
import { MutableSessionContext } from "~/lib/context";
import { showDivisionsIfCompanyHasDivisions } from "~/lib/showDivisions";
import PermissionProvider from "~/ts-components/permissionProvider/PermissionProvider";
import Restricted from "~/ts-components/permissionProvider/Restricted";
import {
  EntitiesDropdown,
  CenteredLoadingSpinner,
  ContentCard,
  GenericErrorText,
  MessageActionButton
} from "~common";

/** Props type for the top-level API-calling react component */
export interface EmployeeNotificationsProps {
  employee: api.Employee;
  onEdit?: () => any;
}

const getSelectedImage = (
  selectedValue: api.StatusNotificationObservingSetting
): string => {
  switch (selectedValue) {
    case "all_company":
      return NotificationAllCompany;
    case "all_division":
      return NotificationAllDivisions;
    case "extended_directs":
      return NotificationExtendedDirect;
    case "direct_reports":
      return NotificationDirectReports;
    case "none":
      return NotificationNone;
  }
};

/** The top-level API-calling react component */
export const EmployeeNotifications: FunctionComponent<
  EmployeeNotificationsProps
> = ({ employee, onEdit }) => {
  const history = useHistory();
  const [saving, setSaving] = useState(false);
  const [teamLeads, setTeamLeads] = useState<api.Employee[]>();
  const [alternateForIds, setAlternateForIds] = useState<string[]>();
  const [statusNotificationConfig, setStatusNotificationConfig] =
    useState<api.EmployeeStatusNotificationConfig>();
  const [initialRadioValue, setInitialRadioValue] =
    useState<api.StatusNotificationObservingSetting>();
  const [selectedRadioValue, setSelectedRadioValue] =
    useState<api.StatusNotificationObservingSetting>();
  const [previewImageUrl, setPreviewImageFromSelection] =
    useState(NotificationNone);
  const { session } = useContext(MutableSessionContext);
  const showDivisions = showDivisionsIfCompanyHasDivisions(session);
  const [permissionSets, setPermissionSets] = useState<string[]>([]);

  const isRadioDirty = initialRadioValue !== selectedRadioValue;
  const isAlternatesForDirty = !isEqual(
    statusNotificationConfig?.alternate_for_ids.sort(),
    (alternateForIds || []).sort()
  );
  const isDirty = isRadioDirty || isAlternatesForDirty;

  const radioItems = [
    {
      label: `Any employee in the company (${session?.company?.name})`,
      value: "all_company",
      image: NotificationAllCompany,
      restrictedTo: ["CompanyWideNotifications.UPDATE"]
    },
    ...(showDivisions
      ? [
          {
            label: `Any employee in ${employee.friendly_name}'s division (${employee.division?.name})`,
            value: "all_division",
            image: NotificationAllDivisions,
            restrictedTo: ["AllDivisionNotifications.UPDATE"]
          }
        ]
      : []),
    {
      label: `Any of ${employee.friendly_name}'s direct reports and any subsidiary reports`,
      value: "extended_directs",
      image: NotificationExtendedDirect,
      restrictedTo: ["ExtendedReportsNotifications.UPDATE"]
    },
    {
      label: `Any of ${employee.friendly_name}'s direct reports`,
      value: "direct_reports",
      image: NotificationDirectReports,
      restrictedTo: ["DirectReportsNotifications.UPDATE"]
    },
    {
      label: "None",
      value: "none",
      image: NotificationNone
    }
  ];

  const updateSelectedRadio = (
    selectedValue: api.StatusNotificationObservingSetting
  ): void => {
    setSelectedRadioValue(selectedValue);
    setPreviewImageFromSelection(getSelectedImage(selectedValue));
  };

  // Load this employee's status config:
  useEffect(() => {
    const setRadioStateValues = (
      observingSetting: api.StatusNotificationObservingSetting
    ): void => {
      let filteredRadioValue = observingSetting;

      // Ensure that we don't end up with no selection if the direct_reports and extended_directs
      // options are hidden.
      if (
        !employee.is_team_lead &&
        (filteredRadioValue === "direct_reports" ||
          filteredRadioValue === "extended_directs")
      ) {
        filteredRadioValue = "none";
      }
      // Ensure that we don't end up with no selection if somehow the division option is hidden.
      else if (!showDivisions && filteredRadioValue === "all_division") {
        filteredRadioValue = "all_company";
      }
      setInitialRadioValue(filteredRadioValue);
      setSelectedRadioValue(filteredRadioValue);

      setPreviewImageFromSelection(
        getSelectedImage(filteredRadioValue || "none")
      );
    };

    const loadConfig = async (): Promise<void> => {
      let response:
        | api.APIResponse<api.EmployeeStatusNotificationConfig>
        | undefined;
      try {
        response = await api.retrieveEmployeeStatusNotificationConfig(
          employee.company_id,
          employee.id
        );
      } catch {}

      if (!response?.ok) {
        toast.error(<GenericErrorText />);
        return;
      }
      setStatusNotificationConfig(response.data);
      setAlternateForIds(response.data.alternate_for_ids);
      setRadioStateValues(response.data.observing);
    };

    loadConfig();
  }, [employee, showDivisions]);

  // Load all team leads (for the alternate managers)
  useEffect(() => {
    const loadTeamLeads = async (): Promise<void> => {
      let response:
        | api.APIResponse<api.PaginatedResponse<api.Employee>>
        | undefined;
      try {
        response = await api.retrieveEmployees(employee.company_id, {
          teamLeadsOnly: true,
          limit: api.MAX_LIMIT,
          onlyUnderScope: true,
          onlyUnderEmployeeScope: employee.id
        });
      } catch {}

      if (!response?.ok) {
        toast.error(<GenericErrorText />);
        return;
      }
      const teamLeads = response.data.results;
      setTeamLeads(teamLeads);
    };

    loadTeamLeads();
  }, [employee.company_id, employee]);

  useEffect(() => {
    setPermissionSets(
      session.permission_sets?.filter(permissionSet =>
        employee.permission_sets?.includes(permissionSet)
      ) || []
    );
  }, [session, employee]);

  const loading = !statusNotificationConfig || !teamLeads;

  // Block navigation for unsaved changes
  useEffect(() => {
    const unblock = history.block(() => {
      if (isDirty) {
        return "Are you sure you want to leave?\nAny changes you have made will be discarded.";
      }
    });
    return unblock;
  }, [isDirty, history]);

  // Update status notification settings for employee
  const onUpdateStatusNotificationConfig = async (): Promise<void> => {
    if (!alternateForIds || !selectedRadioValue || !statusNotificationConfig) {
      return;
    }

    let response:
      | api.APIResponse<api.EmployeeStatusNotificationConfig>
      | undefined;
    try {
      setSaving(true);
      // Don't overwrite the observing setting based on our filtering logic,
      // instead send back the inital "observing" value if the radio button is unchanged
      const observing = isRadioDirty
        ? selectedRadioValue
        : statusNotificationConfig.observing;
      response = await api.updateEmployeeStatusNotificationConfig(
        employee.company_id,
        employee.id,
        {
          alternate_for_ids: alternateForIds,
          observing
        }
      );
    } catch {}

    setSaving(false);
    if (response?.ok) {
      setStatusNotificationConfig(response.data);
      setInitialRadioValue(response.data.observing);
      toast.success(
        `${employee.friendly_name}'s notification settings have been updated.`
      );
    } else {
      toast.error(<GenericErrorText />);
    }
  };

  return (
    <div className="mx-10 max-h-full h-full flex flex-col flex-1">
      {loading && (
        <div className="flex flex-row justify-center min-h-full">
          <CenteredLoadingSpinner />
        </div>
      )}
      {statusNotificationConfig && teamLeads && (
        <ContentCard outerWrapperClassName="p-8 pb-12 max-h-full w-full relative overflow-auto text-hs-dark-green">
          <div className="text-base font-semibold">Status Notifications</div>
          <div className="flex flex-wrap">
            <PermissionProvider
              permissions={permissionSets}
              key={`perm_${radioItems.length}`}
            >
              <Restricted
                to={["AdditionalManagersNotifications.UPDATE"]}
                fallback={
                  <div className="flex flex-col mt-6 space-y-2 max-w-lg">
                    <div className="text-xs text-muted">
                      <strong>
                        {employee.name} cannot receive notifications about
                        others because their role is set to Employee.{" "}
                      </strong>
                      To elevate their role to Team Leader or Administrator,{" "}
                      <span
                        onClick={onEdit}
                        className="text-hs-green text-xs text-center my-auto underline hover:text-hs-dark-green-60 cursor-pointer"
                      >
                        edit {employee.name}’s profile
                      </span>
                      .
                    </div>
                  </div>
                }
              >
                <>
                  <div className="flex flex-col mt-6 space-y-2 max-w-lg">
                    <div className="text-xs font-bold uppercase">
                      Notify {employee.friendly_name} if any of the following
                      employees change status:
                    </div>
                    <div>
                      <form>
                        {radioItems.map(
                          ({ label, image, value, restrictedTo }, index) =>
                            restrictedTo ? (
                              <PermissionProvider
                                permissions={permissionSets}
                                key={`perm_${index}`}
                              >
                                <Restricted
                                  to={restrictedTo}
                                  key={`pane_${index}`}
                                >
                                  <RadioButton
                                    label={label}
                                    name="notificationType"
                                    value={value}
                                    selected={value === selectedRadioValue}
                                    onSelectItem={value => {
                                      updateSelectedRadio(
                                        value as api.StatusNotificationObservingSetting
                                      );
                                    }}
                                  />
                                </Restricted>
                              </PermissionProvider>
                            ) : (
                              <RadioButton
                                label={label}
                                key={`radio_${index}`}
                                name="notificationType"
                                value={value}
                                selected={value === selectedRadioValue}
                                onSelectItem={value => {
                                  updateSelectedRadio(
                                    value as api.StatusNotificationObservingSetting
                                  );
                                }}
                              />
                            )
                        )}
                      </form>
                    </div>
                    <div className="pt-2 text-xs font-bold text-hs-alt-green">
                      AND
                    </div>
                    <div className="pt-2 text-xs font-bold uppercase">
                      Notify {employee.friendly_name} if any of the following
                      managers' direct reports change status:
                    </div>
                    <div className="text-xs font-bold">Managers</div>
                    <EntitiesDropdown
                      allEntities={teamLeads}
                      entityIds={alternateForIds ?? []}
                      onChange={setAlternateForIds}
                    />
                    <div className="text-xs text-ts-gray-35">
                      <span className="font-bold">
                        Not seeing a manager you expect to see?
                      </span>{" "}
                      Make sure that any managers you wish to set up
                      notifications for have been added to Additional Manager
                      Visibility.{" "}
                      <button
                        className="text-ts-teal-50 underline"
                        onClick={onEdit}
                      >
                        Edit settings
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col pl-24 pt-6">
                    <img alt={selectedRadioValue} src={previewImageUrl} />
                  </div>
                </>
              </Restricted>
            </PermissionProvider>
          </div>
          <PermissionProvider permissions={permissionSets}>
            <Restricted to={["AdditionalManagersNotifications.UPDATE"]}>
              <MessageActionButton
                inline
                className="w-32 mt-8"
                disabled={!isDirty || saving}
                text="Save"
                theme="green"
                onClick={onUpdateStatusNotificationConfig}
              />
            </Restricted>
          </PermissionProvider>
        </ContentCard>
      )}
    </div>
  );
};
