import React, { FC, useCallback, useMemo } from "react";
import { Col, Collapse, Row } from "react-bootstrap";

import clsx from "clsx";

import {
  FormData,
  TeamLeadSelectItemOption,
  FormErrors,
  DropDownItemWithDescription
} from "./EditEmployeeModal";

import * as api from "~/api";
import { employeeSearchHandler } from "~/lib/typeaheadHandler";
import Alert from "~/mui-components/Alert/Alert";
import {
  SelectAudience,
  SelectAudienceOptions,
  TSTypeahead,
  TSTypeaheadOption
} from "~common";

// This component was separated from the EditEmployeeModal.tsx in order to enforce permission checks
// If changes are made to the structure of this component, validate permissions still work as expected
export const RolesPermissionsInputs: FC<RolesPermissionsProps> = props => {
  const {
    session,
    formErrors,
    formData,
    typeaheadChangeHandler,
    allEmployeeRoles,
    showDivisions,
    isAdminRoleDivisionsValid,
    companyWideAdminHandler,
    isDowngradeToEmployee,
    disabled
  } = props;

  ///////////////////////////////////
  // EMPLOYEE ROLE OPTION METHODS //
  /////////////////////////////////

  const employeeRoleOptions = useMemo((): TSTypeaheadOption[] => {
    return (
      allEmployeeRoles?.map((item: DropDownItemWithDescription) => ({
        label: item.text,
        value: item.id,
        secondaryLabel: item.secondaryLabel,
        disabled: item.disabled
      })) || []
    );
  }, [allEmployeeRoles]);

  // Retrieve a role by value
  const getRoleOption = useCallback(
    (value: string | undefined): TSTypeaheadOption[] | undefined => {
      if (value === undefined) {
        return;
      }

      const roleMatch = employeeRoleOptions?.find(p => p.value === value);
      return roleMatch
        ? [
            {
              label: roleMatch.label,
              value: roleMatch.value
            }
          ]
        : [];
    },
    [employeeRoleOptions]
  );

  // When a role is selected, fetch the secondary label for it's help text
  const getRoleHelpText = useCallback((): string | undefined => {
    const roleMatchv2 =
      formData?.role &&
      employeeRoleOptions.find(role => role.value === formData?.role);

    return roleMatchv2 && roleMatchv2.secondaryLabel;
  }, [formData?.role, employeeRoleOptions]);

  /////////////////////////////////
  // ADMIN SCOPE OPTION METHODS //
  ///////////////////////////////

  const administratorScopeOptions = useMemo<SelectAudienceOptions>(() => {
    const divisionOptions = (formData?.adminDivisions ?? []).map(item => ({
      label: item.name,
      value: item.id
    }));

    return {
      // If there are no divisions for the company, we want to set this as true
      // Otherwise, use the formData selection
      allCompany: !showDivisions ? true : formData?.allCompany,
      employees: [],
      groups: [],
      divisions: (divisionOptions as TSTypeaheadOption[]) ?? undefined
    };
  }, [formData?.adminDivisions, formData?.allCompany, showDivisions]);

  ////////////////////////////////////////
  // ADDITIONAL MANAGER OPTION METHODS //
  //////////////////////////////////////
  const defaultAdditionalManagerOptions = useMemo(():
    | TSTypeaheadOption[]
    | undefined => {
    return formData?.alternateFor !== undefined
      ? formData?.alternateFor.map(item => ({
          label: item.name,
          value: item.id
        }))
      : [];
  }, [formData]);

  //////////////////////
  // CHANGE HANDLERS //
  ////////////////////

  const roleTypeaheadChangeHandler = (selected: TSTypeaheadOption[]): void => {
    delete formErrors?.adminDivisions;
    delete formErrors?.alternateFor;

    // If role is set to company-wide administrator, we can ignore the check for divisions
    // If we don't ignore it, if divisions is empty user won't be able to save and reason will be hidden
    if (selected[0]?.value === api.EmployeeRole.companyWideAdmin) {
      isAdminRoleDivisionsValid.current = true;
    } else if (selected[0]?.value === api.EmployeeRole.divisionAdmin) {
      // But if it is reset back to division administrator, we want to make sure to check for divisions again
      if (!formData.adminDivisions || formData.adminDivisions.length === 0) {
        isAdminRoleDivisionsValid.current = false;
      }

      adminDivisionsChangeHandler(
        administratorScopeOptions,
        isAdminRoleDivisionsValid.current
      );
    }

    companyWideAdminHandler(
      selected[0]?.value === api.EmployeeRole.companyWideAdmin
    );
    typeaheadChangeHandler<string>("role", selected && selected[0]?.value);
  };

  const adminDivisionsChangeHandler = (
    audience: SelectAudienceOptions,
    isValid: boolean
  ): void => {
    isAdminRoleDivisionsValid.current = isValid;

    delete formData?.adminDivisions;

    const adminDivisions = audience.divisions?.map(
      item =>
        ({
          id: item.value,
          name: item.label
        } as api.Division)
    );
    companyWideAdminHandler(Boolean(audience.allCompany));
    typeaheadChangeHandler("adminDivisions", adminDivisions);
  };

  const alternateForTypeaheadChangeHandler = (
    selected: TeamLeadSelectItemOption[]
  ): void => {
    if (session.company) {
      delete formData?.alternateFor;

      const selectedLead = selected?.map(
        item =>
          ({
            name: item.label,
            id: item.value,
            company_id: session.company?.id
          } as api.TeamLead)
      );

      typeaheadChangeHandler<api.TeamLead[] | undefined>(
        "alternateFor",
        selectedLead
      );
    }
  };

  return (
    <>
      <h1>Roles & Permissions</h1>
      {isDowngradeToEmployee && (
        <Alert severity="warning">
          Any existing notification settings will be removed if role is changed
          to employee.
        </Alert>
      )}
      <Row className="mb-0">
        <TSTypeahead
          className="col-sm-6"
          id="role"
          labelKey="label"
          label="Role"
          onChange={selected =>
            roleTypeaheadChangeHandler(selected as TSTypeaheadOption[])
          }
          options={employeeRoleOptions}
          placeholder="Choose a Role"
          defaultSelected={getRoleOption(formData?.role)}
          helpText={getRoleHelpText()}
          readOnly={disabled}
          errorText={formErrors?.role}
          isInvalid={Boolean(formErrors?.role)}
          required
          showId
          showAllOptions
        />

        <Col sm="6">
          <Collapse in={api.isLeadOrAdmin(formData?.role)}>
            <div>
              <TSTypeahead
                id="alternateFor"
                label="Additional Manager Visibility"
                labelKey="label"
                multiple
                onChange={selected =>
                  alternateForTypeaheadChangeHandler(
                    selected as TeamLeadSelectItemOption[]
                  )
                }
                showId
                options={[]}
                className={clsx(
                  "async-search",
                  disabled && "disable-by-permission"
                )}
                placeholder="Choose Additional Managers"
                readOnly={disabled}
                defaultSelected={defaultAdditionalManagerOptions}
                asyncSearchHandler={nameOrId =>
                  employeeSearchHandler(
                    session,
                    { nameOrId },
                    false,
                    defaultAdditionalManagerOptions?.map(item => item.value)
                  )
                }
                helpText="Direct reports of these managers will be visible. Manager notifications can be edited on the Notifications tab"
              />
            </div>
          </Collapse>
          <Collapse in={formData?.role === api.EmployeeRole.divisionAdmin}>
            <div>
              <SelectAudience
                label="Scope of Role"
                className={clsx(disabled && "disable-by-permission")}
                checkboxPrepend="Administrator for"
                audience={administratorScopeOptions}
                audienceChangeHandler={(
                  audience: SelectAudienceOptions,
                  isValid: boolean
                ) => adminDivisionsChangeHandler(audience, isValid)}
                showErrors={!isAdminRoleDivisionsValid.current}
                requiredMessage="Role scope is required."
                readOnly={disabled}
                enableAllCompany={false}
                enableEmployees={false}
                enableGroups={false}
                enableDirectReports={false}
                hideCheckboxes={["division"]}
              />
            </div>
          </Collapse>
        </Col>
      </Row>
    </>
  );
};

type RolesPermissionsProps = {
  session: api.Session;
  formData: FormData;
  disabled?: boolean;
  formErrors?: FormErrors;
  typeaheadChangeHandler: <T>(name: string, value: T) => void;
  allEmployeeRoles?: DropDownItemWithDescription[];
  showDivisions?: boolean;
  isAdminRoleDivisionsValid?: any;
  companyWideAdminHandler: (value: boolean) => void;
  isDowngradeToEmployee?: boolean;
};
