import * as React from "react";

import Tooltip from "@mui/material/Tooltip/Tooltip";
import { GridColDef, GridColumnHeaderTitle } from "@mui/x-data-grid-pro";
import { Info } from "@phosphor-icons/react";

import { numericOperators, selectOperators } from "../DataGridCommonHeaders";
import {
  MemorizedReactivateButton,
  MemorizedRightArrow,
  MemorizedStatusPills
} from "../DataGridCommonSlots";

import { Group } from "~/api";
import * as api from "~/api";
import { FilterListItem } from "~/lib/filterList";

export enum EmployeeTableHeaders {
  FirstName = "firstName",
  LastName = "lastName",
  Name = "name",
  Role = "role",
  ExternalId = "externalId",
  AttendancePoints = "attendancePoints",
  SickPoints = "sickPoints",
  PtoPoints = "ptoPoints",
  VacationPoints = "vacationPoints",
  FlexPoints = "flexPoints",
  OtherPoints = "otherPoints",
  Status = "status",
  Division = "division",
  Manager = "manager",
  Groups = "groups",
  Arrow = "_arrow",
  Reactivate = "_reactivate"
}

const splitCamelCase = (str: string): string => {
  return str
    .split(/_|-/g)
    .filter(Boolean)
    .map(s => s.charAt(0).toUpperCase() + s.slice(1))
    .join(" ");
};

const trackers = [
  "attendance_points",
  "sick_points",
  "pto_points",
  "vacation_points",
  "flex_points",
  "other_points"
];

const capitalize = (text: string): string =>
  text
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

const gridHeaders = (
  headerName: string,
  props?: {
    headerName?: string;
    balanceUnit?: string;
    options?: FilterListItem[];
    session?: api.Session;
    language?: string;
    onEditStatusClick?: (employee: api.Employee, labelKey: string) => void;
    employee?: api.Employee;
    onReactivateClick?: (employee: api.Employee) => void;
  }
): GridColDef => {
  const basicHeaderProps: Omit<GridColDef, "type" | "field"> = {
    renderCell: params => {
      const { value } = params;
      return value;
    },
    minWidth: 80,
    flex: 1,
    filterable: true,
    sortable: true,
    renderHeader: params => {
      if (trackers.includes(params.field)) {
        const headerName = capitalize(params.colDef.headerName || "");
        const description = (
          <span className="whitespace-pre-line">{`${headerName} (${
            props?.balanceUnit || "hours"
          }) \nView time of last sync on Employee Profile.`}</span>
        );
        return (
          <div className="flex gap-1">
            <GridColumnHeaderTitle
              label={headerName}
              columnWidth={params.colDef.computedWidth}
              description={description}
            />
            <Tooltip title={description}>
              <Info size={16} />
            </Tooltip>
          </div>
        );
      }
    }
  };
  const headers: { [key: string]: GridColDef } = {
    firstName: {
      ...basicHeaderProps,
      filterable: false,
      field: "given_name",
      headerName: "First Name",
      flex: 1
    },
    lastName: {
      ...basicHeaderProps,
      filterable: false,
      field: "family_name",
      headerName: "Last Name",
      flex: 1
    },
    name: {
      ...basicHeaderProps,
      filterable: false,
      flex: 2,
      field: "name",
      headerName: "Employee"
    },
    externalId: {
      ...basicHeaderProps,
      filterable: false,
      field: "external_id",
      headerName: "Employee ID"
    },
    attendancePoints: {
      ...basicHeaderProps,
      field: "attendance_points",
      headerName: props?.headerName || "Points",
      type: "number",
      filterOperators: numericOperators,
      flex: 0.8
    },
    sickPoints: {
      ...basicHeaderProps,
      field: "sick_points",
      headerName: props?.headerName || "Sick",
      type: "number",
      filterOperators: numericOperators,
      flex: 0.8
    },
    ptoPoints: {
      ...basicHeaderProps,
      field: "pto_points",
      headerName: props?.headerName || "PTO",
      type: "number",
      filterOperators: numericOperators,
      flex: 0.8
    },
    vacationPoints: {
      ...basicHeaderProps,
      field: "vacation_points",
      headerName: props?.headerName || "Vacations",
      type: "number",
      filterOperators: numericOperators,
      flex: 0.8
    },
    flexPoints: {
      ...basicHeaderProps,
      field: "flex_points",
      headerName: props?.headerName || "Flex",
      type: "number",
      filterOperators: numericOperators,
      flex: 0.8
    },
    otherPoints: {
      ...basicHeaderProps,
      field: "other_points",
      headerName: props?.headerName || "Other",
      type: "number",
      filterOperators: numericOperators,
      flex: 0.8
    },
    role: {
      ...basicHeaderProps,
      field: "role",
      headerName: "Role",
      type: "singleSelect",
      filterOperators: selectOperators,
      getOptionLabel: (value: any) => value.label || value || "",
      valueOptions: props?.options?.map(option => ({
        value: option.id,
        label: option.name
      })),
      renderCell: params => splitCamelCase(params.value)
    },
    status: {
      ...basicHeaderProps,
      field: "active_events",
      headerName: "Status",
      type: "singleSelect",
      filterOperators: selectOperators,
      getOptionLabel: (value: any) => value.label || value || "",
      valueOptions: props?.options?.map(option => ({
        value: option.id,
        label: option.name
      })),
      renderCell: params => {
        return (
          <MemorizedStatusPills
            activeEvents={params.value ?? []}
            labels={(props?.session?.labels || []) as api.LabelInfoMap}
            language={props?.language ?? "en-US"}
            employee={params.row as api.Employee}
            // onEditStatusClick={props?.onEditStatusClick}
          />
        );
      }
    },
    division: {
      ...basicHeaderProps,
      field: "division",
      headerName: "Division",
      sortable: false,
      type: "singleSelect",
      filterOperators: selectOperators,
      getOptionLabel: (value: any) => value.label || value || "",
      valueOptions: props?.options?.map(option => ({
        value: option.id,
        label: option.name
      })),
      renderCell: params => {
        const divisionName = params.value?.name;
        return divisionName;
      }
    },
    manager: {
      ...basicHeaderProps,
      flex: 1.5,
      field: "team_lead",
      headerName: "Manager",
      sortable: false,
      type: "singleSelect",
      filterOperators: selectOperators,
      getOptionLabel: (value: any) => value.label || value || "",
      valueOptions: props?.options?.map(option => ({
        value: option.id,
        label: option.name
      })),
      renderCell: params => {
        const teamLeadName = params.value?.name;
        return teamLeadName;
      }
    },
    groups: {
      ...basicHeaderProps,
      field: "groups",
      headerName: "Groups",
      sortable: false,
      type: "singleSelect",
      filterOperators: selectOperators,
      getOptionLabel: (value: any) => value.label || value || "",
      valueOptions: props?.options?.map(option => ({
        value: option.id,
        label: option.name
      })),
      renderCell: params => {
        const groups =
          params.value?.map((group: Group) => group.name).join(", ") || "";
        return groups;
      }
    },
    _arrow: {
      minWidth: 40,
      maxWidth: 40,
      field: "_arrow",
      headerName: "",
      type: "custom",
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      groupable: false,
      renderCell: params => <MemorizedRightArrow id={params.id as string} />
    },
    _reactivate: {
      minWidth: 120,
      field: "_reactivate",
      headerName: "",
      type: "custom",
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      groupable: false,
      renderCell: params => (
        <MemorizedReactivateButton
          employee={params.row as api.Employee}
          onReactivateClick={props?.onReactivateClick}
        />
      )
    }
  };

  const header = headers[headerName];
  if (!header) {
    return {
      field: headerName,
      headerName,
      renderCell: params => {
        const { value } = params;
        return value;
      }
    };
  }
  return headers[headerName];
};

export default gridHeaders;
