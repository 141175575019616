import React, { FunctionComponent } from "react";

import { StatusComponent } from "./StatusComponent";

export const TSFooter: FunctionComponent<FooterProp> = () => {
  return (
    <div
      id="footer-wrap"
      className="border-t-2 border-t-ts-gray-80 mx-4 py-4 lg:mx-11 flex flex-col lg:flex-row text-sm flex-wrap items-center justify-center 2xl:justify-between gap-y-4"
    >
      <div id="footer-left" className="lg:mx-0">
        <StatusComponent />
      </div>
      <div
        id="footer-right"
        className="basis-1/2 flex flex-col lg:flex-row justify-center lg:justify-end lg:flex-nowrap  lg:divide-x-[1px] lg:divide-ts-gray-40 items-baseline gap-y-4"
      >
        <div className="pr-2 mx-auto lg:mx-0 whitespace-nowrap">
          &copy; {new Date().getFullYear()} TeamSense
          <small>
            <sup className="z-[-1]">&trade;</sup>
          </small>
        </div>
        <div
          id="link-wrap"
          className="divide-x-[1px] divide-ts-gray-30 mx-auto lg:mx-0"
        >
          <a
            className="px-2 text-ts-teal-40 hover:text-ts-teal-20 whitespace-nowrap"
            href="https://www.teamsense.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          <a
            className="pl-2 text-ts-teal-40 hover:text-ts-teal-20 whitespace-nowrap"
            href="https://www.teamsense.com/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms & Conditions
          </a>
        </div>
      </div>
    </div>
  );
};

interface FooterProp {
  infiniteScroll?: boolean;
}
