import * as React from "react";

import {
  GridColDef,
  GridRenderCellParams,
  GridTreeNodeWithRender
} from "@mui/x-data-grid-pro";

import { basicHeaderProps, arrow, getHeader } from "../DataGridCommonHeaders";
import { MemorizedCustomRightArrow } from "../DataGridCommonSlots";

import { Group } from "~/api";
import * as api from "~/api";
import {
  generateRecipientDisplayText,
  parseRecipients
} from "~/lib/recipientsUtils";
import { TSPill } from "~common";

const renderNestedProp = (
  params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
): string => {
  const [prop, subProp] = params.field.split("__");
  return params.row[prop]?.[subProp];
};

export enum CommunicationGridHeaders {
  Status = "status",
  SendDate = "sendDate",
  Recipients = "recipients",
  Subject = "subject",
  MessageBody = "messageBody",
  SenderName = "senderName",
  SenderId = "senderId",
  Arrow = "_arrow"
}

const ellipsisClassName = "overflow-hidden text-ellipsis whitespace-nowrap";

const gridHeaders = (
  headerName: string,
  props?: {
    session?: api.Session;
    groups?: Group[];
  }
): GridColDef => {
  const headers: { [key: string]: GridColDef } = {
    status: {
      ...basicHeaderProps,
      filterable: true,
      sortable: true,
      field: "sent_at",
      headerName: "Status",
      display: "flex",
      flex: 1,
      maxWidth: 120,
      renderCell: params => {
        const status = params.field in params.row ? "sent" : "scheduled";
        let statusText;
        let statusVariant;

        if (status === "scheduled") {
          if (new Date(params.row.due) < new Date()) {
            statusText = "Pending";
            statusVariant = "warning";
          } else {
            statusText = "Scheduled";
            statusVariant = "info";
          }
        } else {
          statusText = "Sent";
          statusVariant = "light";
        }

        return <TSPill text={statusText} variant={statusVariant} />;
      }
    },
    sendDate: {
      ...basicHeaderProps,
      filterable: true,
      sortable: true,
      field: "due",
      headerName: "Send Date",
      display: "flex",
      flex: 1.5,
      maxWidth: 200,
      renderCell: params => {
        const { value } = params;
        const dueTime = new Date(value);
        const date = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit"
        }).format(new Date(dueTime));
        const time = new Intl.DateTimeFormat("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true
        }).format(dueTime);
        return <div className={ellipsisClassName}>{`${date} ${time}`}</div>;
      }
    },
    recipients: {
      ...basicHeaderProps,
      filterable: true,
      sortable: false,
      display: "flex",
      flex: 3,
      field: "recipients_json",
      headerName: "Recipients",
      renderCell: params => {
        const { value } = params;
        const parsedRecipients = parseRecipients(
          value,
          props?.session,
          props?.groups
        );
        const notParsedRecipientsNumber =
          value.length - parsedRecipients.length;
        const displayText = generateRecipientDisplayText(
          parsedRecipients,
          notParsedRecipientsNumber
        );
        return <div className={ellipsisClassName}>{displayText}</div>;
      }
    },
    subject: {
      ...basicHeaderProps,
      filterable: true,
      sortable: true,
      display: "flex",
      flex: 2,
      field: "context_json__email_subject",
      headerName: "Subject",
      renderCell: params => (
        <div className={ellipsisClassName}>{renderNestedProp(params)}</div>
      )
    },
    messageBody: {
      ...basicHeaderProps,
      filterable: true,
      sortable: false,
      display: "flex",
      flex: 3,
      field: "context_json__email_body",
      headerName: "Message Body",
      renderCell: params => (
        <div
          className={`relative inline -top-px text-[14px]`}
          style={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical"
          }}
        >
          {renderNestedProp(params)}
        </div>
      )
    },
    senderName: {
      ...basicHeaderProps,
      filterable: true,
      sortable: true,
      display: "flex",
      flex: 1,
      field: "created_by__name",
      headerName: "Sender Name",
      renderCell: params => (
        <div className={ellipsisClassName}>{renderNestedProp(params)}</div>
      )
    },
    senderId: {
      ...basicHeaderProps,
      filterable: true,
      sortable: true,
      display: "flex",
      flex: 1,
      field: "created_by__external_id",
      headerName: "Sender ID",
      renderCell: params => (
        <div className={ellipsisClassName}>{renderNestedProp(params)}</div>
      )
    },
    _arrow: {
      ...arrow,
      renderCell: () => (
        <MemorizedCustomRightArrow className="communicate-open-modal" />
      )
    }
  };

  return getHeader(headerName, headers);
};

export default gridHeaders;
